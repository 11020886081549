<template>
    <div class="widget-container chart-widget" :style="{width: '100%', minHeight: '280px', justifyContent: 'flex-start'}">
        <vs-row class="mb-2" vs-justify="space-between">
            <div class="widget-title" :style="{display: 'flex', width: '100%', justifyContent: 'space-between'}">Patient Count
            
                <QuestionTooltip>
                    <template slot="header">Patient Count</template>
                    <template slot="body">Number of patients treated over time, broken down by New and Returning patients. A returning patient is anyone who has had an injectables treatment at your clinic in the last 12 months</template>
                </QuestionTooltip>
            </div>
            <div class="widget-options flex" style="justify-content: flex-end;">
            </div>
        </vs-row>
        <div class="widget-chart" style="position: relative;  width: 100%; height: 100%;">
            <div 
                class="skeleton skeleton-chart-columns" 
                style="
                    --cols: 10;
                    --col-w: 30px;
                    --col-gap: 20px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                "
                v-if="loadingChart || isFetchingMainFilters"
            ></div>
            <template v-else>
                <BarChart 
                    v-if="patientCountData.datasets.length"
                    :chartData="patientCountData"
                    :chartOptions="patientCountOptions"
                    :height="230"
                    :width="250"
                />
                <div v-else class="no-data-available">No Data Available</div>
            </template>
        </div>
    </div>
</template>
<script>
import BarChart from '../charts/BarChart.vue'
import QuestionTooltip from '../common/QuestionTooltip'

export default {
    components: {
        QuestionTooltip,
        BarChart
    },
    props: {
            patientCountData: {
                type: Object,
                required: true
            },
            loadingChart: {
                type: Boolean,
                default: false
            },
            isFetchingMainFilters: {
                type: Boolean,
                default: false
            },
    },
    data() {
        return {
            patientCountOptions: {
                interaction: {
                    intersect: false,
                        axis: 'x',
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        pointStyleWidth	: 14,
                        boxHeight: 10,
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            pointStyle: "circle",
                            usePointStyle: true,
                            padding: 20,
                            color: "#0E4333",
                            font: {
                                family: "InterMedium",
                                size: 12,
                            }
                        }
                    },
                    title: {
                        display: false,
                        text: ''
                    },
                    tooltip: {
                        mode: 'index',
                        caretSize: 0,
                        caretPadding: 15,
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        titleFont: {
                            family: 'InterSemiBold',
                            size: 16
                        },
                        bodySpacing: 3,
                        bodyFont: {
                            family: 'Inter',
                            size: 14
                        },
                        usePointStyle: true,
                        callbacks: {
                            labelColor: function(context) {
                                return {
                                    borderColor: context.dataset.backgroundColor,
                                    backgroundColor: context.dataset.backgroundColor,
                                };
                            },
                            labelPointStyle: function(context) {
                                return {
                                    pointStyle: 'circle',
                                };
                            }
                        },
                        boxWidth: 10,
                        boxHeight: 10,
                        boxPadding: 8,
                        borderWidth: 0,
                        padding: 15
                    }
                },
                scales: {
                    y: {
                        title: {
                            display: false,
                        },
                        ticks: {
                            color: "#0E4333",
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                        },
                        grid: {
                            color: '#EFF1F4',
                            borderColor: '#EFF1F4',
                            tickColor: '#EFF1F4'
                        }
                    },
                    x: {
                        title: {
                            display: false,
                        },
                        grid : {
                            display : true,
                            color: '#EFF1F4'
                        },
                        ticks: {
                            color: "#0E4333",
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                        }
                    }
                }
            }
        }
    }
}
</script>