<template>
    <div class="widget-container chart-widget">
        <div class="widget-title text-center">
            Patient Mix
            <QuestionTooltip>
                <template slot="header">Patient Mix</template>
                <template slot="body">
                    Proportion of injectables treatments that are provided to New and Returning Customers. A returning patient is anyone who has had an injectables treatment at your clinic in the last 12 months
                </template>
            </QuestionTooltip>
        </div>
        <div class="widget-divider-wrapper widget-count text-center">
            <vs-col class="divider-right" vs-w="6">
                <p class="count-percentage" v-if="!loadingChart && !isFetchingMainFilters">{{ returningPatientsPercentage }}%</p>
                <p class="count-number" v-if="!loadingChart && !isFetchingMainFilters">({{ returningPatients }})</p>
                <div class="skeleton skeleton-line" v-if="loadingChart || isFetchingMainFilters" style="--c-p: 5px; --c-w: 100px;margin: 0 auto;"></div>
                <div class="skeleton skeleton-line" v-if="loadingChart || isFetchingMainFilters" style="--c-p: 5px; --c-w: 50px;margin: 0 auto;"></div>

                <p class="count-label">Returning Patients</p>
            </vs-col>
            <vs-col vs-w="6">
                <p class="count-percentage" v-if="!loadingChart && !isFetchingMainFilters">{{ firstTimePatientsPercentage }}%</p>
                <p class="count-number" v-if="!loadingChart && !isFetchingMainFilters">({{ firstTimePatients }})</p>
                <div class="skeleton skeleton-line" v-if="loadingChart || isFetchingMainFilters" style="--c-p: 5px; --c-w: 100px;margin: 0 auto;"></div>
                <div class="skeleton skeleton-line" v-if="loadingChart || isFetchingMainFilters" style="--c-p: 5px; --c-w: 50px;margin: 0 auto;"></div>
                <p class="count-label">New Patients</p>
            </vs-col>
        </div>
    </div>
</template>
<script>
import QuestionTooltip from '../common/QuestionTooltip'

export default {
    components: {
        QuestionTooltip
    },
    props: {
            returningPatientsPercentage: {
                type: Number,
                default: 0
            },
            returningPatients: {
                type: Number,
                default: 0
            },
            firstTimePatientsPercentage: {
                type: Number,
                default: 0
            },
            firstTimePatients: {
                type: Number,
                default: 0
            },
            loadingChart: {
                type: Boolean,
                default: false
            },
            isFetchingMainFilters: {
                type: Boolean,
                default: false
            },
    }
}
</script>