<template>
    <div class="widget-container chart-widget">
        <vs-row vs-justify="space-between">
            <div class="widget-title" :style="{display: 'flex', width: '100%', justifyContent: 'space-between'}">
                <template v-if="widgetTitle">
                    {{ widgetTitle  }}
                </template>
                <template v-else>
                    Treatment Mix - {{selectedNurseName ? selectedNurseName : "Your clinic"}}
                </template>
            
                <QuestionTooltip>
                    <template v-if="widgetTitle" slot="header">{{ widgetTitle  }}</template>
                    <template v-else slot="header">Treatment Mix - {{selectedNurseName ? selectedNurseName : "Your clinic"}}</template>

                    <template slot="body">Breakdown of the treatments completed within your clinic</template>
                </QuestionTooltip>
            </div>
            <div class="widget-options flex" style="justify-content: flex-end;">
            </div>
        </vs-row>
        <div :style="{height: '100%', paddingBottom: '5px'}">
            <template v-if="loadingChart || isFetchingMainFilters">
                <div class="skeleton skeleton-line" style="--c-p: 10px; --c-w: 300px;margin: 0 auto;"></div>
                <div class="skeleton skeleton-line" style="--c-p: 10px; --c-w: 250px;margin: 0 auto;"></div>
                <div class="skeleton skeleton-line" style="--c-p: 10px; --c-w: 350px;margin: 0 auto;"></div>
                <div class="skeleton skeleton-line" style="--c-p: 10px; --c-w: 200px;margin: 0 auto;"></div>
                <div class="skeleton skeleton-line" style="--c-p: 10px; --c-w: 320px;margin: 0 auto;"></div>
            </template>
            <template v-else>
                <BarChart 
                    v-if="treatmentMixYourClinicData.datasets.length"
                    :height="150"
                    :chartData="treatmentMixYourClinicData"
                    :chartOptions="treatmentMixOptions"
                />
                <div v-else class="no-data-available">No Data Available</div>
            </template>
        </div>
    </div>
</template>
<script>
import BarChart from '../charts/BarChart.vue'
import QuestionTooltip from '../common/QuestionTooltip'

export default {
    components: {
        QuestionTooltip,
        BarChart
    },
    props: {
            widgetTitle: {
                type: String,
                default: ''
            },
            selectedNurseName: {
                type: String,
                default: ''
            },
            treatmentMixYourClinicData: {
                type: Object,
                required: true
            },
            loadingChart: {
                type: Boolean,
                default: false
            },
            isFetchingMainFilters: {
                type: Boolean,
                default: false
            },
    },
    data() {
        return {
            treatmentMixOptions: {
                indexAxis: 'y',
                interaction: {
                    intersect: false,
                    axis: 'y',
                },
                responsive: true,
                plugins: {
                    datalabels: {
                        opacity: 1,
                        color: function(context) {
                            return context.dataset.labelColor ? context.dataset.labelColor : '#fff';
                        },
                        display: true,
                        font: {
                            family: "InterMedium",
                            size: 14
                        },
                        formatter: function(value) {
                            return (value) ? Math.round(value) + '%' : '';
                        }
                    },
                    legend: {
                        pointStyleWidth	: 14,
                        boxHeight: 10,
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            pointStyle: "circle",
                            usePointStyle: true,
                            padding: 20,
                            color: "#0E4333",
                            font: {
                                family: "InterMedium",
                                size: 12,
                            }
                        }
                    },
                    title: {
                        display: true,
                        text: ''
                    },
                    tooltip: {
                        caretSize: 0,
                        caretPadding: 15,
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        titleFont: {
                            family: 'InterSemiBold',
                            size: 16
                        },
                        bodySpacing: 3,
                        bodyFont: {
                            family: 'Inter',
                            size: 14
                        },
                        usePointStyle: true,
                        callbacks: {
                            labelColor: function(context) {
                                return {
                                    borderColor: context.dataset.backgroundColor,
                                    backgroundColor: context.dataset.backgroundColor,
                                };
                            },
                            labelPointStyle: function(context) {
                                return {
                                    pointStyle: 'circle',
                                };
                            },
                            label: function(context) {
                                let label = context.dataset.label || '';

                                if (label) {
                                    label += ': ' + Math.round(context.formattedValue) + '%';
                                }
                                return label;
                            }
                        },
                        boxWidth: 10,
                        boxHeight: 10,
                        boxPadding: 8,
                        borderWidth: 0,
                        padding: 15
                    },
                },
                scales: {
                    y: {
                        title: {
                            display: false,
                        },
                        stacked: true,
                        ticks: {
                            display: false
                        },
                        grid: {
                            color: '#EFF1F4',
                            borderColor: '#EFF1F4',
                            tickColor: '#EFF1F4',
                            drawTicks: false,
                        }
                    },
                    x: {
                        title: {
                            display: false
                        },
                        stacked: true,
                        ticks: {
                            color: "#0E4333",
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                            // Include a percentage sign in the ticks
                            callback: function(value, index, ticks) {
                                return value + '%';
                            }
                        },
                        grid: {
                            color: '#EFF1F4',
                            borderColor: '#EFF1F4',
                            tickColor: '#EFF1F4'
                        },
                        min: 0,
                        max: 100
                    }
                }   
            }
        }
    }
}
</script>