<template>
    <div class="nurse-dashboard-top">
        <vs-row vs-type="grid" class="top-grids current-number-grids">
            <InjectableAppointmentsWidget 
                :totalAppointments="formatCurrency(currentAppointments.total_appointments, 0)"
                :loadingChart="loadingCharts.totalAppointments"
                :isFetchingMainFilters="isFetchingMainFilters"
            />
            <PatientMixWidget 
                :returningPatientsPercentage="customerMix.returning_patients_percentage ? customerMix.returning_patients_percentage : 0"
                :returningPatients="customerMix.returning_patients ? customerMix.returning_patients : 0"
                :firstTimePatientsPercentage="customerMix.first_time_patients_percentage ? customerMix.first_time_patients_percentage : 0"
                :firstTimePatients="customerMix.first_time_patients ? customerMix.first_time_patients : 0"
                :loadingChart="loadingCharts.customerMix"
                :isFetchingMainFilters="isFetchingMainFilters"
            />
            <HaventBookedPatientsWidget 
                :numberOfClients="(currentNumberOfClientsNotBooked.number_of_clients) ? currentNumberOfClientsNotBooked.number_of_clients : 0"
                :loadingChart="loadingCharts.totalAppointments"
                :isFetchingMainFilters="isFetchingMainFilters"
            />
            <!-- <div class="widget-container chart-widget">
                <div class="widget-title text-center" :style="{display: 'flex',maxWidth: '205px'}"># of patients who haven't booked in 6-18 months
                    <QuestionTooltip :style="{top: '9px'}">
                        <template slot="header"># of patients who haven't booked in 6-18 months</template>
                        <template slot="body">Number of patients who have had a previous injectables appointment, but have not had an appointment within the last 6-18 months</template>
                    </QuestionTooltip>
                </div>
                <div class="widget-count text-center">
                    {{ (!loadingCharts.clientsNotBooked && !isFetchingMainFilters) ? (currentNumberOfClientsNotBooked.number_of_clients) ? currentNumberOfClientsNotBooked.number_of_clients : 0 : '' }}
                    <div class="skeleton skeleton-line" v-if="loadingCharts.clientsNotBooked || isFetchingMainFilters" style="--c-w: 60px;"></div>
                </div>
            </div> -->
        </vs-row>
    </div>
</template>
<script>
import utils from '@/assets/utils'
import BarChart from '../../components/insights/charts/BarChart.vue'
import DoughnutChart from '../../components/insights/charts/DoughnutChart.vue'
import LineChart from '../../components/insights/charts/LineChart.vue'
import QuestionTooltip from '../../components/insights/common/QuestionTooltip'
import { mapActions } from "vuex";
import moment from "moment";
import { filter, slice, find, omitBy } from "lodash";
import InjectableAppointmentsWidget from "../../components/insights/widgets/InjectableAppointmentsWidget.vue";
import PatientMixWidget from "../../components/insights/widgets/PatientMixWidget.vue";
import HaventBookedPatientsWidget from "../../components/insights/widgets/HaventBookedPatientsWidget.vue";

export default {
    props: {
        selectedNurse: {
            type: String,
            default: ''
        },
        selectedNurseName: {
            type: String,
            default: ''
        },
        selectedClinic: {
            type: Array,
            default: () => []
        },
        isFetchingMainFilters: {
            type: Boolean,
            default: false
        },
        selectedDate: {
            type: Array,
            default: () => []
        }
    },
    components: {
        BarChart,
        DoughnutChart,
        LineChart,
        QuestionTooltip,
        InjectableAppointmentsWidget,
        PatientMixWidget,
        HaventBookedPatientsWidget
    },
    data() {
        return {
            loadingCharts: {
                totalAppointments: false,
                customerMix: false,
                clientsNotBooked: false,
            },
            currentAppointments: {
                total_appointments: 0
            },
            customerMix: {
                first_time_patients: 0,
                returning_patients: 0,
                first_time_patients_percentage: 0,
                returning_patients_percentage: 0
            },
            currentNumberOfClientsNotBooked: {
                number_of_clients: 0
            },
        }
    },
    methods: {
        moment(date) {
            return moment(date);
        },
        ...mapActions("analytics", [
            "fetchClinicCurrentNumberPatients",
            "fetchClinicMixPatientsCount",
            "fetchClinicNumberOfClientsNotBooked",
        ]),
        formatCurrency(value, round) {
            let val = (value/1).toFixed(round)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        fetchClinicPatients() {
            // Reset currentAppointments
            this.currentAppointments = {
                total_appointments: 0
            }
            
            const payload = {
                clinic: this.selectedClinic,
                nurse: this.selectedNurse ? [this.selectedNurse] : [],
                start: this.moment(this.selectedDate[0]).format("YYYY-MM-DD"),
                end: this.moment(this.selectedDate[1]).format("YYYY-MM-DD")
            }
            this.loadingCharts.totalAppointments = true
            this.fetchClinicCurrentNumberPatients(payload).then((res) => {
                if(res && res.data)
                    if(res.data.length)
                        this.currentAppointments = res.data[0]

                this.loadingCharts.totalAppointments = false;
            })
            .catch((err) => {
                console.error("##ERROR: ", err.message);
                this.loadingCharts.totalAppointments = false;
            });
        },
        fetchClinicCustomerMix() {
            const payload = {
                clinic: this.selectedClinic,
                nurse: this.selectedNurse ? [this.selectedNurse] : [],
                start: this.moment(this.selectedDate[0]).format("YYYY-MM-DD"),
                end: this.moment(this.selectedDate[1]).format("YYYY-MM-DD")
            }
            this.loadingCharts.customerMix = true
            this.fetchClinicMixPatientsCount(payload).then((res) => {
                if(res && res.data)
                    if(res.data.length)
                        this.customerMix = res.data[0]

                this.loadingCharts.customerMix = false;
            })
            .catch((err) => {
                console.error("##ERROR: ", err.message);
                this.loadingCharts.customerMix = false;
            });
        },
        fetchClinicCurrentNumberOfClientsNotBooked(){
            const payload = {
                clinic: this.selectedClinic,
                nurse: this.selectedNurse ? [this.selectedNurse] : [],
                start: this.moment(this.selectedDate[0]).format("YYYY-MM-DD"),
                end: this.moment(this.selectedDate[1]).format("YYYY-MM-DD")
            }
            this.loadingCharts.clientsNotBooked = true
            this.fetchClinicNumberOfClientsNotBooked(payload).then((res) => {
                if(res && res.data)
                    if(res.data.length)
                        this.currentNumberOfClientsNotBooked = res.data[0]

                this.loadingCharts.clientsNotBooked = false;
            })
            .catch((err) => {
                console.error("##ERROR: ", err.message);
                this.loadingCharts.clientsNotBooked = false;
            });
            
        },
        fetchClinicPerformance(){
            if(this.selectedClinic.length) {
                this.fetchClinicPatients()
                this.fetchClinicCustomerMix()
                this.fetchClinicCurrentNumberOfClientsNotBooked()
            }            
        },
    },
    watch: {
        selectedNurse: {
            handler(newVal) {
                this.fetchClinicPerformance()
            },
            immediate: true,
        },
        selectedClinic: {
            handler(newVal) {
                this.fetchClinicPerformance()
            },
            immediate: true,
        },
        selectedDate: {
            handler(newVal) {
                if(newVal.length) {
                    this.fetchClinicPerformance()
                }
            },
            immediate: true,
        }
    },
}
</script>
<style>
.nurse-dashboard-top .chart-widget {
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.widget-divider-wrapper {
    padding-top: 10px;
    width: 100%;
}
.divider-right {
    border-right: 1px solid #F2F2F2;
}
.top-grids .widget-title {
    font-size: 14px;
}
.count-percentage {
    font-size: 24px;
}
.count-number {
    font-size: 11px;
    margin-bottom: 12px;
}
.count-label {
    font-size: 10px;
}
</style>