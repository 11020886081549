<template>
    <div class="widget-container chart-widget">
        <div class="widget-title text-center">Number of Appointments 
            <QuestionTooltip>
                <template slot="header">Number of Appointments</template>
                <template slot="body">
                    Number of complete injectables appointments
                </template>
            </QuestionTooltip>
        </div>
        <div class="widget-count text-center">
            {{ (!loadingChart && !isFetchingMainFilters) ? totalAppointments : '' }}
            <div class="skeleton skeleton-line" v-if="loadingChart || isFetchingMainFilters" style="--c-w: 60px;"></div>
        </div>
    </div>
</template>
<script>
import QuestionTooltip from '../common/QuestionTooltip'

export default {
    components: {
        QuestionTooltip
    },
    props: {
            totalAppointments: {
                type: String,
                default: ''
            },
            loadingChart: {
                type: Boolean,
                default: false
            },
            isFetchingMainFilters: {
                type: Boolean,
                default: false
            },
    }
}
</script>