<template>
    <div class="widget-container chart-widget">
        <div class="widget-title text-center" :style="{display: 'flex',maxWidth: '205px'}"># of patients who haven't booked in 6-18 months
            <QuestionTooltip :style="{top: '9px'}">
                <template slot="header"># of patients who haven't booked in 6-18 months</template>
                <template slot="body">Number of patients who have had a previous injectables appointment, but have not had an appointment within the last 6-18 months</template>
            </QuestionTooltip>
        </div>
        <div class="widget-count text-center">
            {{ (!loadingChart && !isFetchingMainFilters) ? numberOfClients : '' }}
            <div class="skeleton skeleton-line" v-if="loadingChart || isFetchingMainFilters" style="--c-w: 60px;"></div>
        </div>
    </div>
</template>
<script>
import QuestionTooltip from '../common/QuestionTooltip'

export default {
    components: {
        QuestionTooltip
    },
    props: {
            numberOfClients: {
                type: Number,
                default: 0
            },
            loadingChart: {
                type: Boolean,
                default: false
            },
            isFetchingMainFilters: {
                type: Boolean,
                default: false
            },
    }
}
</script>