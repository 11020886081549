<template>
    <div id="nurse-dashboard-bottom">
        <vs-row vs-type="flex">
            <div class="overall-market">Overall Market</div>
        </vs-row>

        <vs-row vs-type="grid" class="overall-market-grids">
            <div class="widget-container chart-widget" :style="{paddingBottom: '5px', minHeight: '280px'}">
                <vs-row vs-justify="space-between">
                    <div class="widget-title" :style="{display: 'flex', width: '100%', justifyContent: 'space-between'}">Appointment Volumes (Aus Wide)
                        <QuestionTooltip>
                            <template slot="header">Appointment Volumes (Aus Wide)</template>
                            <template slot="body">Number of appointments completed Australia wide across the Fresh Clinics network. </template>
                        </QuestionTooltip>
                    </div>
                    <div class="widget-options flex" style="justify-content: flex-end;">
                    </div>
                </vs-row>
                
                <div style="position: relative;  width: 100%; height: 100%; padding-top: 15px;">
                    <div 
                        class="skeleton skeleton-chart-columns" 
                        style="
                            --cols: 10;
                            --col-w: 30px;
                            --col-gap: 20px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        "
                        v-if="loadingCharts.appointmentVolumes || isFetchingMainFilters"
                    ></div>
                    <template v-else>
                        <BarChart 
                            v-if="appointmentVolumesData.datasets.length"
                            :chartData="appointmentVolumesData"
                            :chartOptions="overallMarketChartOptions"
                            :height="230"
                            :width="250"
                        />
                        <div v-else class="no-data-available">No Data Available</div>
                    </template>
                </div>
            </div>
            
            <div class="widget-container chart-widget" :style="{paddingBottom: '5px', minHeight: '280px'}">
                <vs-row vs-justify="space-between">
                    <div class="widget-title" :style="{display: 'flex', width: '100%', justifyContent: 'space-between'}">Product Volumes (Aus Wide)
                    
                        <QuestionTooltip>
                            <template slot="header">Product Volumes (Aus Wide)</template>
                            <template slot="body">Value of products purchased. Does not include scripting fees</template>
                        </QuestionTooltip>
                    </div>
                    <div class="widget-options flex" style="justify-content: flex-end;">
                    </div>
                </vs-row>
                
                
                <div style="position: relative;  width: 100%; height: 100%; padding-top: 15px;">
                    <div 
                        class="skeleton skeleton-chart-columns" 
                        style="
                            --cols: 10;
                            --col-w: 30px;
                            --col-gap: 20px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        "
                        v-if="loadingCharts.productVolumes || isFetchingMainFilters"
                    ></div>
                    <template v-else>
                        <BarChart 
                            v-if="productVolumesData.datasets.length"
                            :chartData="productVolumesData"
                            :chartOptions="overallMarketChartOptions"
                            :height="230"
                            :width="250"
                        />
                        <div v-else class="no-data-available">No Data Available</div>
                    </template>
                </div>
            </div>
        </vs-row>
    </div>
</template>
<script>
import BarChart from '../../components/insights/charts/BarChart.vue'
import QuestionTooltip from '../../components/insights/common/QuestionTooltip'
import { mapActions } from "vuex";
import moment from "moment";
import { slice } from "lodash";

export default {
    props: {
        selectedNurse: {
            type: String,
            default: ''
        },
        selectedNurseName: {
            type: String,
            default: ''
        },
        selectedClinic: {
            type: Array,
            default: () => []
        },
        isFetchingMainFilters: {
            type: Boolean,
            default: false
        },
        selectedDate: {
            type: Array,
            default: () => []
        }
    },
    components: {
        BarChart,
        QuestionTooltip
    },
    data() {
        return {
            loadingCharts: {
                appointmentVolumes: false,
                productVolumes: false,
            },
            appointmentVolumesData: {
                labels: [],
                datasets: []
            },
            productVolumesData: {
                "labels": [],
                "datasets": []
            },
            overallMarketChartOptions: {
                interaction: {
                    intersect: false,
                        axis: 'x',
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        pointStyleWidth	: 14,
                        boxHeight: 10,
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            pointStyle: "circle",
                            usePointStyle: true,
                            padding: 20,
                            color: "#0E4333",
                            font: {
                                family: "InterMedium",
                                size: 12,
                            }
                        }
                    },
                    title: {
                        display: false,
                        text: ''
                    },
                    tooltip: {
                        mode: 'index',
                        caretSize: 0,
                        caretPadding: 15,
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        titleFont: {
                            family: 'InterSemiBold',
                            size: 16
                        },
                        bodySpacing: 3,
                        bodyFont: {
                            family: 'Inter',
                            size: 14
                        },
                        usePointStyle: true,
                        callbacks: {
                            labelColor: function(context) {
                                return {
                                    borderColor: context.dataset.backgroundColor,
                                    backgroundColor: context.dataset.backgroundColor,
                                };
                            },
                            labelPointStyle: function(context) {
                                return {
                                    pointStyle: 'circle',
                                };
                            },
                            label: function(context) {
                                let label = context.dataset.label || '';

                                if (label) {
                                    label += ': ';
                                }

                                let changedValue = 0
                                const value = parseInt(context.formattedValue)
                                if(context.dataIndex){
                                    // check if context data is an object
                                    if(typeof context.dataset.data[context.dataIndex - 1] === 'object'){
                                        // context data is an array of objects
                                        changedValue = value - context.dataset.data[context.dataIndex - 1].y
                                    } else {
                                        // context data is an array of numbers
                                        changedValue = value - context.dataset.data[context.dataIndex - 1]
                                    }
                                    
                                    label += (changedValue > 0) ? '+'+changedValue+'%' : changedValue+'%'
                                } else if(context.dataIndex === 0 && context.dataset.originalData && context.dataset.originalData.length > context.dataset.data.length) {
                                    // check from originalData to get the change value for the first column bar
                                    const prevIndex = context.dataset.originalData.length - context.dataset.data.length
                                    // check if context data is an object
                                    if(typeof context.dataset.data[prevIndex - 1] === 'object'){
                                        // context data is an array of objects
                                        changedValue = value - context.dataset.originalData[prevIndex - 1].y
                                    } else {
                                        // context data is an array of numbers
                                        changedValue = value - context.dataset.originalData[prevIndex - 1]
                                    }
                                    
                                    label += (changedValue > 0) ? '+'+changedValue+'%' : changedValue+'%'
                                } else {
                                    // display empty tooltip if there's no previous month data
                                    label = ''
                                }
                                
                                return label;
                            }
                        },
                        boxWidth: 10,
                        boxHeight: 10,
                        boxPadding: 8,
                        borderWidth: 0,
                        padding: 15
                    },
                },
                scales: {
                    y: {
                        title: {
                            display: false,
                            text: ''
                        },
                        ticks: {
                            display: false
                        },
                        grid: {
                            color: '#EFF1F4',
                            borderColor: '#EFF1F4',
                            borderWidth: 0,
                            tickWidth: 0,
                            drawTicks: false,
                        }
                    },
                    x: {
                        title: {
                            display: false,
                        },
                        grid : {
                            display : false,
                        },
                        ticks: {
                            color: "#0E4333",
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                        }
                    }
                }   
            },
        }
    },
    methods: {
        moment(date) {
            return moment(date);
        },
        ...mapActions("analytics", [
            "fetchClinicAppointmentVolumes",
            "fetchClinicProductVolumes",
        ]),
        fetchClinicCurrentAppointmentVolumes(){
            // Reset Chart
            this.appointmentVolumesData = {
                labels: [],
                datasets: []
            }

            const payload = {
                clinic: this.selectedClinic
            }
            this.loadingCharts.appointmentVolumes = true
            this.fetchClinicAppointmentVolumes(payload).then((res) => {
                if(res && res.data)
                    if(res.data.length)
                        this.initializeAppointmentVolumes(res.data)

                this.loadingCharts.appointmentVolumes = false;
            })
            .catch((err) => {
                console.error("##ERROR: ", err.message);
                this.loadingCharts.appointmentVolumes = false;
            });
            
        },
        initializeAppointmentVolumes(data){
            const self = this;

            let latestData = data;

            // get latest 6 months of data
            if(latestData.length > 6) {
                latestData = slice(data, data.length - 6)
            }

            const dateLabels = latestData.map((value) => {
                return self.moment(value.trunc_month, 'YYYY-MM-DD').format('MMM YYYY')
            })
            
            const originalData = data.map(({volume}) => volume)
            latestData = latestData.map(({volume}) => volume)

            this.appointmentVolumesData.labels = dateLabels
            this.appointmentVolumesData.datasets = [{
                    "label": "Appointment Volume",
                    "backgroundColor": "#236E55",
                    "borderColor": "#236E55",
                    "data": latestData,
                    "originalData": originalData
            }]
        },
        fetchClinicCurrentProductVolumes(){
            // Reset Chart
            this.productVolumesData = {
                "labels": [],
                "datasets": []
            }

            const payload = {
                clinic: this.selectedClinic
            }
            this.loadingCharts.productVolumes = true
            this.fetchClinicProductVolumes(payload).then((res) => {
                if(res && res.data)
                    if(res.data.length)
                        this.initializeProductVolumes(res.data)

                this.loadingCharts.productVolumes = false;
            })
            .catch((err) => {
                console.error("##ERROR: ", err.message);
                this.loadingCharts.productVolumes = false;
            });
            
        },
        initializeProductVolumes(data){
            const self = this;

            let latestToxinData = data;
            let latestFillerData = data;

            // get latest 6 months of toxin/filler data
            if(latestToxinData.length > 6 && latestFillerData.length > 6) {
                latestToxinData = slice(latestToxinData, latestToxinData.length - 6)
                latestFillerData = slice(latestFillerData, latestFillerData.length - 6)
            }

            const dateLabels = latestToxinData.map((value) => {
                return self.moment(value.trunc_month, 'YYYY-MM-DD').format('MMM YYYY')
            })

            const originalToxinData = data.map(({toxin_volume}) => toxin_volume)
            const originalFillterData = data.map(({filler_volume}) => filler_volume)
            const latestToxinVolumeValues = latestToxinData.map(({toxin_volume}) => toxin_volume)
            const latestFillerVolumeValues = latestFillerData.map(({filler_volume}) => filler_volume)

            const originalToxinProductVolume = originalToxinData.map((value, index) => {
                return {
                    x: originalFillterData[index],
                    y: value
                }
            })

            const originalFillerProductVolume = originalToxinData.map((value, index) => {
                return {
                    x: value,
                    y: originalFillterData[index]
                }
            })

            const toxinProductVolume = latestToxinVolumeValues.map((value, index) => {
                return {
                    x: latestFillerVolumeValues[index],
                    y: value
                }
            })

            const fillerProductVolume = latestToxinVolumeValues.map((value, index) => {
                return {
                    x: value,
                    y: latestFillerVolumeValues[index]
                }
            })

            this.productVolumesData.labels = dateLabels
            this.productVolumesData.datasets = [{
                "label": "Anti-wrinkle Volume (units)",
                "data": toxinProductVolume,
                "backgroundColor": "#236E55",
                "originalData": originalToxinProductVolume,
            },{
                "label": "Filler Volume (mL)",
                "data": fillerProductVolume,
                "backgroundColor": "#99E5DB",
                "originalData": originalFillerProductVolume
            }]
        },
        fetchClinicPerformance(){
            if(this.selectedClinic.length) {
                if(!this.loadingCharts.appointmentVolumes) {
                    this.fetchClinicCurrentAppointmentVolumes()
                }
                if(!this.loadingCharts.productVolumes) {
                    this.fetchClinicCurrentProductVolumes()
                }
            }            
        },
    },
    watch: {
        selectedNurse: {
            handler(newVal) {
                this.fetchClinicPerformance()
            },
            immediate: true,
        },
        selectedClinic: {
            handler(newVal) {
                this.fetchClinicPerformance()
            },
            immediate: true,
        },
        selectedDate: {
            handler(newVal) {
                if(newVal.length) {
                    this.fetchClinicPerformance()
                }
            },
            immediate: true,
        }
    },
}
</script>
<style>
#nurse-dashboard-bottom .chart-widget {
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.overall-market {
    background-color: #236E55;
    color: #F2F2F2;
    font-family: 'InterSemiBold';
    font-size: 16px;
    padding: 4px;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    margin-bottom: 12px;
}
.overall-market-grids {
    /* grid-template-columns: 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 12px;
}
</style>