<template lang="html">
    <div class="question-tooltip-icon">
        <el-tooltip popper-class="question-tooltip-popper" placement="top">
            <div class="question-tooltip-content" slot="content">
                <div class="title-content">
                    <slot name="header">
                        <!-- content for the header slot -->
                    </slot>
                </div>
                <div class="body-content">
                    <slot name="body">
                        <!-- content for the body slot -->
                    </slot>
                </div>
            </div>
            <el-button>
                <img
                    :src="require('@/assets/images/insights/question-square.svg')"
                    width="18"
                    height="18" 
                />
            </el-button>
        </el-tooltip>
    </div>
</template>
<script>
export default {
    props: {
    }
}
</script>
<style>
.question-tooltip-icon {
    display: inline-block;
    position: relative;
    top: 4px;
    padding: 3px;
}
.question-tooltip-icon button {
    padding: 1px;
    border: 0;
}
.question-tooltip-icon button:active, 
.question-tooltip-icon button:focus, 
.question-tooltip-icon button:hover {
    background-color: transparent;
    border-color: transparent;
    border: none;
}
.question-tooltip-popper {
    border-radius: 8px;
    z-index: 999999 !important;
}
.question-tooltip-content {
    max-width: 350px;
    padding: 4px;
}
.question-tooltip-content .title-content {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 6px;
    font-family: 'Inter';
    font-weight: 600;
    line-height: 16px;
    color: #fff;
    font-size: 10px;
}
.question-tooltip-content .body-content {
    font-family: 'Inter';
    font-weight: 400;
    line-height: 18px;
    font-size: 12px;
}
</style>