<template>
    <div class="nurse-dashboard-wrapper" :class="$route.name + '-page'">
        <vs-row id="nurse-dashboard-header-options" class="widget-container" vs-justify="space-between">
            <div></div>
            <div class="header-dropdown">
                <vs-col class="dropdown-selection">
                    <!-- Date Range -->
                    <div
                        class="select-date-range"
                    >
                        <custom-month-range-picker
                            :selectedDate="selectedDate"
                            @change="updateDateRangeValues"
                            date-format="MMM yy"
                            :disabled="loadingDropdownFilter || isFetchingMainFilters">
                        </custom-month-range-picker>
                    </div>
                </vs-col>
                <vs-col class="dropdown-selection" :class="{ 'loading-filter' : loadingDropdownFilter || isFetchingMainFilters }" v-if="userType === 'superAdmin'">
                    <div class="lds-dual-ring" v-if="loadingDropdownFilter || isFetchingMainFilters"></div>
                    <el-select
                        popper-class="dashboard-header-popper"
                        :disabled="loadingDropdownFilter || isFetchingMainFilters"
                        v-model="selectedOrg"
                        @change="updateClinicsFilter"
                        filterable
                        placeholder="Organizations">
                        <el-option
                            v-for="item in optionsOrgs"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </vs-col>
                <vs-col class="dropdown-selection" style="min-width: 217px" :class="{ 'loading-filter' : loadingDropdownFilter || isFetchingMainFilters }">
                    <div class="lds-dual-ring" v-if="loadingDropdownFilter || isFetchingMainFilters"></div>
                    <el-select
                            class="multi-select-filter"
                            multiple 
                            collapse-tags
                            popper-class="dashboard-header-popper"
                            :disabled="loadingDropdownFilter || isFetchingMainFilters"
                            v-model="selectedClinic"
                            @change="updateNursesFilter"
                            filterable
                            placeholder="Clinics">
                            <el-option v-if="optionsClinics.length" label="all" :value="selectedClinic.length === optionsClinics.length ? 'unall' : 'all'" :disabled="loadingDropdownFilter || isFetchingMainFilters">
                                <span style="float: left">{{selectedClinic.length === optionsClinics.length ? 'Unselect All Clinics' : 'Select All Clinics'}}</span>
                            </el-option>
                            <el-option
                                :disabled="loadingDropdownFilter || isFetchingMainFilters"
                                v-for="item in optionsClinics"
                                :key="item.value + item.label"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                </vs-col>
                <vs-col v-if="['SuperAdmin'].includes(this.urlType)" class="dropdown-selection" :class="{ 'loading-filter' : loadingDropdownFilter || isFetchingMainFilters }">
                    <div class="lds-dual-ring" v-if="loadingDropdownFilter || isFetchingMainFilters"></div>
                    <el-select
                        popper-class="dashboard-header-popper"
                        :disabled="loadingDropdownFilter || isFetchingMainFilters"
                        v-model="selectedNurse"
                        filterable
                        placeholder="Nurses">
                        <el-option
                            v-for="item in optionsNurses"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </vs-col>
            </div>
        </vs-row>
        <div class="nurse-dashboard-content">
            <ClinicNurseDashboardTop :selectedNurse="selectedNurse" :selectedNurseName="selectedNurseLabel" :selectedClinic="selectedClinic" :isFetchingMainFilters="isFetchingMainFilters" :selectedDate="selectedDate" />
            <ClinicNurseDashboardMiddle :selectedNurse="selectedNurse" :selectedNurseName="selectedNurseLabel" :selectedClinic="selectedClinic" :isFetchingMainFilters="isFetchingMainFilters" :selectedDate="selectedDate" />
            <ClinicNurseDashboardBottom :selectedNurse="selectedNurse" :selectedNurseName="selectedNurseLabel" :selectedClinic="selectedClinic" :isFetchingMainFilters="isFetchingMainFilters" :selectedDate="selectedDate" />
        </div>
        <ClinicNurseDashboardFooter />
    </div>
  </template>

<script>
import ClinicNurseDashboardTop from './ClinicNurseDashboardTop'
import ClinicNurseDashboardMiddle from './ClinicNurseDashboardMiddle'
import ClinicNurseDashboardBottom from './ClinicNurseDashboardBottom'
import ClinicNurseDashboardFooter from './ClinicNurseDashboardFooter'
import { mapState, mapActions, mapGetters } from "vuex";
import moment from "moment";
import { findIndex } from "lodash";
import 'css-skeletons'
import CustomMonthRangePicker from "../../components/insights/common/GlobalFilters/CustomMonthRangePicker.vue";

export default {
    components: {
        ClinicNurseDashboardTop,
        ClinicNurseDashboardMiddle,
        ClinicNurseDashboardBottom,
        ClinicNurseDashboardFooter,
        CustomMonthRangePicker
    },
    data() {
        return {
                isFetchingMainFilters: false,
                selectedDate: [this.moment().subtract(12, 'months').startOf('month'), this.moment().endOf('month')],
                userType: '',
                filter_query_type: ["clinics", "organizations", "nurse"],
                filterNurses: [],
                filterClinics: [],
                filterOrganizations: [],
                selectedNurse: null,
                selectedNurseName: "",
                urlType: "",
                selectedClinic: [],
                selectedOrg: null,
                loadingDropdownFilter: false,
            };
    },
    computed: {
        ...mapGetters("analytics", ["DEFAULT_STATE_FILTER"]),
        ...mapState("analytics", ["filters"]),
        optionsNurses() {
            const defaultNurse = { value: '', label: 'All  Nurses' }
            let nurseOptions = this.filterNurses.map(nurse => (
                { value: nurse.value, label: nurse.text }
            ))
            if(nurseOptions.length) {
                nurseOptions.unshift(defaultNurse)
            }
            return nurseOptions
        },
        allClinicsValue(){
            return this.filterClinics.map(clinic => clinic.value)
        },
        optionsClinics() {
            let clinicOptions =  this.filterClinics.map(clinic => (
                { value: clinic.value, label: clinic.text }
            ))
            return clinicOptions
        },
        optionsOrgs() {
            return this.filterOrganizations.map(org => (
                { value: org.value, label: org.text }
            ))
        },
        selectedOrgLabel() {
            if(this.selectedOrg) {
                const self = this;
                const orgIndex = findIndex(this.filterOrganizations, function(org) { return org.value == self.selectedOrg; });
                return this.filterOrganizations[orgIndex].text
            }
            return ''
        },
        selectedNurseLabel() {
            if(["SuperAdmin", "Supplier"].includes(this.urlType)) {
                if(this.selectedNurse) {
                    const self = this;
                    const nurseIndex = findIndex(this.filterNurses, function(nurse) { return nurse.value == self.selectedNurse; });
                    return this.filterNurses[nurseIndex].text
                } 
            } else {
                if(this.selectedNurseName) {
                    return this.selectedNurseName
                } else if(this.selectedNurse) {
                    const self = this;
                    const nurseIndex = findIndex(this.filterNurses, function(nurse) { return nurse.value == self.selectedNurse; });
                    return this.filterNurses[nurseIndex].text
                } 
            }
            return ''
        }
    },
    methods: {
        ...mapActions("analytics", [
            "fetchAnalyticsFilters"
        ]),
        moment(date) {
            return moment(date);
        },
        updateDateRangeValues(dateRange) {
            if(dateRange) {
                this.selectedDate = [this.moment(dateRange[0]).startOf('month'), this.moment(dateRange[1]).endOf('month')];
            } else {
                this.selectedDate = [];
            }
        },
        gotoInsightsTools() {
            if(this.userType === "superAdmin") {
                this.$router.push("/super-admin/insights").catch(() => {});
            } else {
                this.$router.push("/org-owner/insights").catch(() => {});
            }
        },
        updateClinicsFilter() {
            // reset subfilters
            this.filterClinics = []
            this.selectedClinic = []
            this.filterNurses = []
            if(["SuperAdmin"].includes(this.urlType)) {
                this.selectedNurse = null
            }

            // setup payload
            const payloadWithOrg = {
                filter_query_type: this.filter_query_type,
                org: [this.selectedOrg]
            };
            this.loadingDropdownFilter = true;
            this.fetchAnalyticsFilters(payloadWithOrg).then(() => {
                this.filterClinics = this.filters.clinic ? this.filters.clinic : []
                this.selectedClinic = (this.filters.clinic.length) ? [this.filters.clinic[0].value] : []
                if(this.selectedClinic.length && this.selectedClinic.length < this.filters.clinic.length) {
                    this.selectedClinic = ['all']
                }
                this.updateNursesFilter(this.selectedClinic)
            })
            .catch((err) => {
                console.error("##ERROR: ", err);
                this.loadingDropdownFilter = false;
            });
        },
        updateNursesFilter(e) {
            if(e){
                if(e.includes('all')){
                    this.selectedClinic = this.allClinicsValue;
                }
                if(e.includes('unall')){
                    this.selectedClinic = []
                }
            }
            if(["SuperAdmin"].includes(this.urlType)) {
                //reset subfilters
                this.filterNurses = []
                this.selectedNurse = null
                if(this.selectedClinic.length) {
                    const payloadWithOrg = {
                        filter_query_type: this.filter_query_type,
                        clinic: this.selectedClinic
                    };
                    this.loadingDropdownFilter = true;
                    this.fetchAnalyticsFilters(payloadWithOrg).then(() => {
                        this.loadingDropdownFilter = false;
                        this.filterNurses = this.filters.nurse
                        this.selectedNurse = (this.filters.nurse.length) ? '' : null
                    })
                    .catch((err) => {
                        console.error("##ERROR: ", err);
                        this.loadingDropdownFilter = false;
                    });
                }
            } else {
                this.loadingDropdownFilter = false;
            }
        },
        async checkIsAnalyticsEnabled() {
            const organizationId = localStorage.getItem(
                `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
            );
            const { data } = await this.$store.dispatch(
                "organization/fetchOrganizationDetails",
                organizationId
            );

            const isAnalyticsEnabled = data.data.isAnalyticsEnabled;
            if (!isAnalyticsEnabled) {
                const matched = this.$route.matched
                if(matched.length && matched[0].path) {
                    this.$router.push(`${matched[0].path}/notice-board`);
                } else {
                    this.$router.push("/");
                }
            }
        },
    },
    mounted() {
        const userRole = JSON.parse(
            localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
        );
        this.userType = userRole.userType

        if(userRole && userRole.userType === "superAdmin"){
            // Do Nothing
        } else if(userRole && userRole.organizations[0].role.toUpperCase() ===
        "supplier".toUpperCase()){
            // Do Nothing
        } else {
            // Disabled analytics checking here. Checking by each router instead
            // this.checkIsAnalyticsEnabled();
        }

    },
    created() {
        const org_id = [localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)]
        
        const payload = {
            filter_query_type: this.filter_query_type,
        };

        const user = JSON.parse(
            localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
        );
        
        if(user && user.userType === "superAdmin"){
            this.urlType = "SuperAdmin";
        }
            
        if (!["SuperAdmin"].includes(this.urlType)) {
            payload.org = org_id
            payload.nurse = [user.id]
            this.selectedNurse = user.id
            this.selectedNurseName = user.firstname + ' ' + user.lastname
        }

        this.isFetchingMainFilters = true;

        this.fetchAnalyticsFilters(payload).then(() => {
            
            if(this.filters && this.filters.clinic) {
                this.filterClinics = this.filters.clinic ? this.filters.clinic : []
                this.filterOrganizations = this.filters.org ? this.filters.org : []
                
                if(this.userType === "superAdmin") {
                    this.selectedOrg = (this.filters.org.length) ? this.filters.org[0].value : null
                    this.updateClinicsFilter()
                } else {
                    this.selectedClinic = (this.filters.clinic.length) ? [this.filters.clinic[0].value] : []
                    if(this.selectedClinic.length && this.selectedClinic.length < this.filters.clinic.length) {
                        this.selectedClinic = ['all']
                    }
                    this.updateNursesFilter(this.selectedClinic)
                }
            }
            this.isFetchingMainFilters = false;
        })
        .catch((err) => {
          console.error("##ERROR: ", err);
          this.isFetchingMainFilters = false;
        });
    },
};
</script>
<style>
.nurse-dashboard-wrapper {
    color: #0E4333;
    font-family: 'InterMedium';
}
/* .nurse-dashboard-wrapper .select-date-range {
    min-width: 260px;
} */
.navbar-floating .vx-navbar {
    box-shadow: none;
}
.nurse-dashboard-content {
    position: relative;
}

.dashboard-header-popper.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    color: #37695B;
    font-family: 'InterSemiBold';
}
#nurse-dashboard-header-options .el-tag.el-tag--info {
    max-width: 120px;
}
#nurse-dashboard-header-options {
    border-radius: 0.5rem;
    border: 1px solid #e3eae8;
    background: #fff;
    top: 0;
    box-shadow: none;
    justify-content: flex-end;
}

#nurse-dashboard-header-options .el-select .el-input__inner {
    height: 37px !important;
    text-overflow: ellipsis;
}
#nurse-dashboard-header-options .el-select .el-select__tags {
    max-width: 194px !important;
}
#nurse-dashboard-header-options .el-select .el-select__tags > span + input{
    margin-left: 5px;
}
#nurse-dashboard-header-options .el-select .el-input__inner,
#nurse-dashboard-header-options .el-select .el-input.is-focus .el-input__inner,
#nurse-dashboard-header-options .el-select .el-input__inner:focus,
#nurse-dashboard-header-options .el-select .el-input__inner:hover {
    border: 1px solid #CBD5E1 !important;
}

.dropdown-selection.loading-filter .el-input__icon {
    display: none;
}

.dashboard-header-popper.el-select-dropdown .selected {
    color: #37695B;
    font-family: 'InterSemiBold';
}

.dashboard-header-popper.el-select-dropdown .el-select-dropdown__item {
    font-family: 'Inter';
}

.nurse-dashboard-wrapper .con-ul-tabs .line-vs-tabs {
    box-shadow: none !important;
    height: 1px !important;
    background-color: #0E4333 !important;
}
.nurse-dashboard-wrapper .vs-tabs--ul {
    border-radius: 0.5rem 0.5rem 0 0;
    box-shadow: none;
    border: 1px solid #E3EAE8;
}
.nurse-dashboard-wrapper .el-date-editor,
.nurse-dashboard-wrapper .con-select .vs-select--input {
    border: 1px solid #CBD5E1 !important;
}
.nurse-dashboard-wrapper .el-date-editor .el-range-input {
    font-size: 13px;
}
.nurse-dashboard-wrapper .header-dropdown .multi-select-filter {
    width: 100%;
}
.nurse-dashboard-wrapper .header-dropdown .con-select {
    width: 100%;
}
.nurse-dashboard-wrapper .header-dropdown .dropdown-selection {
    max-width: 200px;
}
/* .navbar-floating .router-content {
    margin-top: 4.5rem;
} */
.sticky .navbar-default {
    padding-bottom: 0 !important;
}
.router-header {
    margin-bottom: 0 !important;
}
.nurse-dashboard-wrapper .header-dropdown {
    max-width: 824px;
    display: flex;
    margin-top: 0;
    gap: 8px;
}
.widget-container {
    position: relative;
    align-items: center;
    background: rgb(255, 255, 255);
    border-radius: 0.5rem;
    padding: 0.8rem 1rem;
    -webkit-box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
    margin-bottom:12px;
}
.clinic-title {
    position: relative;
}
.clinic-title .lds-dual-ring:after {
    width: 25px;
    height: 25px;
}
.clinic-title .lds-dual-ring {
    left: 0;
    top: -24px;
}
.clinic-title .main-title {
    color: #074230;
    font-weight: 800;
}
.current-number-grids {
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 12px;
    margin-bottom: 12px;
}
.widget-count {
    font-size: 24px;
    font-weight: 800;
}
.widget-title {
    font-size: 16px;
    font-family: 'InterSemiBold';
}
.widget-subinfo .subinfo-percent.increased {
    color: rgba(var(--vs-primary), 1);
}
.widget-subinfo .subinfo-percent.decreased {
    color: rgba(var(--vs-danger), 1);
}
.widget-subinfo .subinfo-percent i {
    position: relative;
    top: 3px;
    margin-right: 3px;
}
.widget-subinfo .subinfo-label {
    color: #aaa;
}
.widget-subinfo .subinfo-label::before {
    content: '•';
    color: #aaa;
    font-size: 40px;
    line-height: 0;
    position: relative;
    top: 9px;
    opacity: 0.8;
    margin: 0 5px;
}
.chart-grids {
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}
.OrgOwnerClinicDashboard-page {
    margin-top: 15px;
}
.dropdown-selection {
    position: relative
}
.loading-filter .icon-select {
    display: none;
}
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
}
.dropdown-selection .lds-dual-ring {
    right: 0;
    top: 0;
    z-index: 999;
    width: auto;
    height: auto;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 4px solid #888;
  border-color: #888 transparent #888 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.dropdown-selection .lds-dual-ring:after {
    width: 15px;
    height: 15px;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.nurse-dashboard-wrapper .chart-widget {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: none;
    border: 1px solid #F2F2F2;
}
.nurse-dashboard-wrapper .no-data-available {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #999999;
    padding:50px;
}
.chart-widget .lds-dual-ring {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.loading-widget {
    opacity: 0.5;
}
.widget-ai-btn {
    padding: 0px 10px !important
}
.widget-options button {
    padding: 5px 15px !important;
    margin: 0 5px;
}
.widget-ai-btn img {
    width: 18px;
}
.insights-tool-btn button {
    margin: 0 5px;
    min-width: 130px;
    top: 0;
    height: 36px;
    padding: 9px 12px 10px 12px;
}
.search-ai-container button {
    min-width: 130px;
    top: 21px;
    height: 36px;
    background: rgba(var(--vs-primary),1)!important;
    color: #fff !important;
}
.search-ai-container button:hover {
    background: rgba(var(--vs-primary),1)!important;
    color: #fff !important;
    -webkit-box-shadow: 0 8px 25px -8px rgba(var(--vs-primary),1);
    box-shadow: 0 8px 25px -8px rgba(var(--vs-primary),1);
}

@media only screen and (max-width: 991px) {
    #nurse-dashboard-header-options {
        right: 0
    }
    .nurse-dashboard-wrapper .header-dropdown {
        width: 100%;
        justify-content: center;
    }
}

@media only screen
  and (min-width: 992px)
  and (max-width: 1445px) {
    .nurse-dashboard-wrapper .header-dropdown {
        max-width: 625px;
    }
    .nurse-dashboard-wrapper .el-date-editor .el-range-input {
        font-size: 9px !important;
        padding-top: 2px;
    }
    
    #nurse-dashboard-options .el-select .el-input__inner {
        font-size: 10px;
        padding-top: 2px;
    }
}

@media only screen
  and (max-width: 1252px) {
    .nurse-dashboard-wrapper .header-dropdown {
        max-width: 586px;
    }

    
    .nurse-dashboard-wrapper .el-date-editor {
        min-width: 125px;
    }
    
    .nurse-dashboard-wrapper .el-date-editor .el-range-input {
        font-size: 9px;
        padding-top: 2px;
    }
    
    #nurse-dashboard-header-options .el-select .el-input__inner {
        font-size: 10px;
        padding-top: 2px;
    }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .nurse-dashboard-wrapper {
        margin-top: -34.5px;
    }
    
    .dashboard-header-popper {
        width: 100% !important;
    }

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .nurse-dashboard-wrapper .header-dropdown {
        max-width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .nurse-dashboard-wrapper .header-dropdown .dropdown-selection {
        max-width: 100%;
        min-width: auto !important;
    }
    .nurse-dashboard-wrapper .header-dropdown .dropdown-selection .el-select{
        width: 100%;
    }
}
</style>
